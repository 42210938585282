<template>
 <div class="inner-section">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.schedule') }}  {{  id > 0 ? $t('globalTrans.edit') : $t('globalTrans.add') }}</h4>
      </template>
          <b-container fluid>
              <b-row>
              <b-overlay :show="loading">
                <b-col sm="12" class="mt-3">
                  <ValidationObserver ref="mainForm" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                            <b-row>
                          <slot>
                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                              <ValidationProvider name="Event Type" vid="event_type" rules="required|min_value:1">
                                  <b-form-group
                                    label-for="event_type"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('teaGardenBtriService.event_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                   :disabled="$route.params.id"
                                    plain
                                    v-model="formData.event_type"
                                    :options="eventTypeList"
                                    id="event_type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <!-- :disabled="arrScheduleEntryData.length > 0" -->
                                    <template v-slot:first>
                                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Place (En)" vid="place_en" rules="required">
                                  <b-form-group
                                    label-for="place_en"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('teaGardenBtriService.place')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    plain
                                    v-model="formData.place_en"
                                    id="place_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <!-- :disabled="arrScheduleEntryData.length > 0" -->
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Place (Bn)" vid="place_bn" rules="required">
                                  <b-form-group
                                    label-for="place_bn"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('teaGardenBtriService.place')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    plain
                                    v-model="formData.place_bn"
                                    id="place_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <!-- :disabled="arrScheduleEntryData.length > 0" -->
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          </slot>
                            </b-row>
                          <!-- </b-form> -->
                        <!-- </b-overlay> -->
                  <!-- </ValidationObserver> -->
                  <slot v-if="formData.event_type === 1">
                            <!-- =============End Main========= -->
                      <template>
                            <div style="font-size:18px; background-color: #214162;border-radius:5px" class="p-1">
                                <h5 class="text-white text-left">
                                    {{ $t('teaGardenBtriService.training') }} {{ $t('globalTrans.details') }}
                                </h5>
                            </div>
                      </template>
                        <hr>
                        <!-- ================= Training Start===================== -->
                      <div >
                        <!-- <ValidationObserver ref="trainingForm" v-slot="{ handleSubmit, reset }"> -->
                          <!-- <b-overlay :show="loading"> -->
                            <!-- <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data"> -->
                              <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Training Name (En)" vid="training_name_en" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="training_name_en"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.training_name')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      v-model="formData.training_name_en"
                                      id="training_name_en"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Training Name (Bn)" vid="training_name_bn" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="training_name_bn"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.training_name')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      v-model="formData.training_name_bn"
                                      id="training_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                </b-row>
                                <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Date From" vid="date_from" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="date_from"
                                      slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.date_from') }}<span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date_from"
                                        class="form-control"
                                        v-model="formData.date_from"
                                        :placeholder="$t('globalTrans.select')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Date To" vid="date_to" v-slot="{ errors }" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="date_to"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.date_to') }}<span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date_to"
                                        class="form-control"
                                        v-model="formData.date_to"
                                        :placeholder="$t('globalTrans.select')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                </b-row>
                                <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Time From" vid="time_from" v-slot="{ errors }" rules="required">
                                  <b-form-group
                                    label-cols-sm="4"
                                    label-for="time_from">
                                    <template v-slot:label>
                                      {{ $t('globalTrans.time_from') }}  <span class="text-danger">*</span>
                                    </template>
                                    <input
                                    id="time_from"
                                    type="time"
                                    v-model="formData.time_from"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                    >
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Time To" vid="time_to" v-slot="{ errors }" rules="required">
                                  <b-form-group
                                    label-cols-sm="4"
                                    label-for="time_to">
                                    <template v-slot:label>
                                      {{ $t('globalTrans.time_to') }}  <span class="text-danger">*</span>
                                    </template>
                                    <input
                                    id="time_to"
                                    type="time"
                                    v-model="formData.time_to"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                    >
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                              </b-row>
                            <!-- </b-form> -->
                          <!-- </b-overlay> -->
                        <!-- </ValidationObserver> -->
                      </div>
                      <br/>
                  </slot>
                  <slot v-if="formData.event_type === 2">
                          <!-- =============End Main========= -->
                    <template>
                          <div style="font-size:18px; background-color: #214162;border-radius:5px" class="p-1">
                              <h5 class="text-white text-left">
                                  {{ $t('teaGardenBtriService.workshop') }} {{ $t('globalTrans.details') }}
                              </h5>
                          </div>
                    </template>
                      <hr>
                      <!-- =================Workshop Add More Start===================== -->
                    <div >
                      <ValidationObserver ref="workshopForm" v-slot="{ handleSubmit, reset }">
                          <!-- <b-overlay :show="loading"> -->
                            <b-form @submit.prevent="handleSubmit(scheduleEntryAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                              <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Workshop Title (En)" vid="workshop_title_en" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="workshop_title_en"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.workshop_title')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      v-model="formData.workshop_title_en"
                                      id="workshop_title_en"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Workshop Title (Bn)" vid="workshop_title_bn" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="workshop_title_bn"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.workshop_title')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      v-model="formData.workshop_title_bn"
                                      id="workshop_title_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Date From" rules="required" vid="date_from" v-slot="{ errors }">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="date_from"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.date_from') }} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date_from"
                                        class="form-control"
                                        v-model="formData.date_from"
                                        :placeholder="$t('globalTrans.select')"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Date To" rules="required" vid="date_to" v-slot="{ errors }">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="date_to"
                                    >
                                      <template v-slot:label>
                                        {{ $t('globalTrans.date_to') }} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date_to"
                                        class="form-control"
                                        v-model="formData.date_to"
                                        :placeholder="$t('globalTrans.select')"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <br/>
                                <hr/>
                                <body-card>
                                <template v-slot:headerTitle>
                                  <h4 class="card-title">{{ $t('teaGardenBtriService.event_information') }}</h4>
                                </template>
                                <template v-slot:body>
                                  <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Event Name (En)" vid="event_name_en" :rules="formData.scheduleEntryDataArr.length > 0 ? '' : 'required'">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="event_name_en"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenBtriService.event_name')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      v-model="scheduleEntryData.event_name_en"
                                      id="event_name_en"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Event Name (Bn)" vid="event_name_bn" :rules="formData.scheduleEntryDataArr.length > 0 ? '' : 'required'">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="event_name_bn"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('teaGardenBtriService.event_name')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="scheduleEntryData.event_name_bn"
                                        id="event_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Start Time" vid="start_time" v-slot="{ errors }" :rules="formData.scheduleEntryDataArr.length > 0 ? '' : 'required'">
                                  <b-form-group
                                    label-cols-sm="4"
                                    label-for="start_time">
                                    <template v-slot:label>
                                      {{ $t('globalTrans.start_time') }}  <span class="text-danger">*</span>
                                    </template>
                                    <input
                                    id="start_time"
                                    type="time"
                                    v-model="scheduleEntryData.start_time"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                    >
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                </b-row>
                                </template>
                                </body-card>
                              </b-row>
                              <b-row class="text-right">
                                <b-col>
                                    <b-button type="submit"
                                    variant="success" class="mr-2 btn-sm">
                                        <span v-if="loading">
                                            <b-spinner small label="Loading..."></b-spinner>
                                        </span>
                                        <span v-else>
                                            <i class="fas fa-plus-circle m-0"></i>
                                        </span>
                                        {{arrScheduleEntryData && arrScheduleEntryData.length > 0 ? $t('stock_management.add_more') : $t('globalTrans.add')}}
                                    </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          <!-- </b-overlay> -->
                        </ValidationObserver>
                    </div>
                    <br/>
                      <!-- Budget Details Start -->
                       <div class="table-wrapper table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr class="bg-primary">
                                        <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th style="width: 15%" scope="col" class="text-center"><div>{{ $t('globalTrans.time') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('teaGardenBtriService.event_name') }}</div></th>
                                        <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                      <template v-if="formData.scheduleEntryDataArr && formData.scheduleEntryDataArr.length > 0">
                                          <tr v-for="(item, index) in formData.scheduleEntryDataArr" :key="index">
                                              <td  class="text-center">{{ $n(index+1) }}</td>
                                              <td class="text-center">{{ item.start_time | time12Formate }}</td>
                                              <td class="text-center">{{ currentLocale === 'bn' ? item.event_name_bn : item.event_name_en }}</td>
                                              <td class="text-center">
                                                  <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="scheduleEntryRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                              </td>
                                          </tr>
                                      </template>
                                      <template v-else>
                                          <tr>
                                              <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                          </tr>
                                      </template>
                                  </tbody>
                            </table>
                        </div>
                        <!-- Budget Details End -->
                    <!-- =================Add More End===================== -->
                    <br>
                    <!-- attachemnt  -->
                </slot>
                <slot v-if="formData.event_type === 3">
                            <!-- =============End Main========= -->
                      <template>
                            <div style="font-size:18px; background-color: #214162;border-radius:5px" class="p-1">
                                <h5 class="text-white text-left">
                                    {{ $t('teaGardenBtriService.course') }} {{ $t('globalTrans.details') }}
                                </h5>
                            </div>
                      </template>
                        <hr>
                        <!-- =================Course Add More Start===================== -->
                      <div >
                        <!-- <ValidationObserver ref="courseForm" v-slot="{ handleSubmit, reset }"> -->
                            <!-- <b-overlay :show="loading"> -->
                              <!-- <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data"> -->
                                <b-row>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Course Name (En)" vid="course_name_en" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="course_name_en"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('teaGardenBtriService.course_name')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="formData.course_name_en"
                                        id="course_name_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Course Name (Bn)" vid="course_name_bn" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="course_name_bn"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('teaGardenBtriService.course_name')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="formData.course_name_bn"
                                        id="course_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Date From" vid="date_from" v-slot="{ errors }" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="date_from"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.date_from') }}<span class="text-danger">*</span>
                                        </template>
                                        <date-picker
                                          id="date_from"
                                          class="form-control"
                                          v-model="formData.date_from"
                                          :placeholder="$t('globalTrans.select')"
                                          :class="errors[0] ? 'is-invalid' : ''"
                                        >
                                        </date-picker>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Date To" vid="date_to" v-slot="{ errors }" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="date_to"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.date_to') }}<span class="text-danger">*</span>
                                        </template>
                                        <date-picker
                                          id="date_to"
                                          class="form-control"
                                          v-model="formData.date_to"
                                          :placeholder="$t('globalTrans.select')"
                                          :class="errors[0] ? 'is-invalid' : ''"
                                        >
                                        </date-picker>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Time From" vid="time_from" v-slot="{ errors }" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="time_from">
                                      <template v-slot:label>
                                        {{ $t('globalTrans.time_from') }}  <span class="text-danger">*</span>
                                      </template>
                                      <input
                                      id="time_from"
                                      type="time"
                                      v-model="formData.time_from"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Time To" vid="time_to" v-slot="{ errors }" rules="required">
                                    <b-form-group
                                      label-cols-sm="4"
                                      label-for="time_to">
                                      <template v-slot:label>
                                        {{ $t('globalTrans.time_to') }}  <span class="text-danger">*</span>
                                      </template>
                                      <input
                                      id="time_to"
                                      type="time"
                                      v-model="formData.time_to"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Description (En)" vid="description_en" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="description_en"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('globalTrans.description')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="formData.description_en"
                                        id="description_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                      <b-form-group
                                        label-cols-sm="4"
                                        label-for="description_bn"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('globalTrans.description')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                        v-model="formData.description_bn"
                                        id="description_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                              <!-- </b-form> -->
                            <!-- </b-overlay> -->
                          <!-- </ValidationObserver> -->
                      </div>
                      <br/>
                  </slot>
                <b-row class="text-right mb-3">
                    <b-col>
                        <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                        <b-button variant="danger" class="btn-sm" @click="cancel">{{ $t('globalTrans.cancel') }}</b-button>
                    </b-col>
                </b-row>
                </b-form>
                </ValidationObserver>
                </b-col>
                </b-overlay>
              </b-row>
          </b-container>
     </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { prepareScheduleStore, prepareScheduleUpdate } from '../../api/routes'
import BreadCumb from '../../../../../components/BreadCumb.vue'
export default {
  name: 'Form',
  props: ['id'],
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        place_en: '',
        place_bn: '',
        training_name_en: '',
        training_name_bn: '',
        date_from: '',
        date_to: '',
        time_from: '',
        time_to: '',
        event_type: 0,
        workshop_title_en: '',
        workshop_title_bn: '',
        scheduleEntryDataArr: []
      },
      scheduleEntryData: {
        event_name_en: '',
        event_name_bn: '',
        start_time: ''
      },
      arrScheduleEntryData: [],
      isDisabled: 0
      // itemList: []
    }
  },
  created () {
    if (this.$route.params.id) {
      const tmp = this.getEditEntry()
      this.formData = tmp
      this.formData.scheduleEntryDataArr = tmp.details
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    eventTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
      ]
      return list
    }
  },
  watch: {
    'formData.event_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.$route.params.id) {
          this.formData.place_en = ''
          this.formData.place_bn = ''
          this.formData.training_name_en = ''
          this.formData.training_name_bn = ''
          this.formData.date_from = ''
          this.formData.date_to = ''
          this.formData.time_from = ''
          this.formData.time_to = ''
          this.formData.workshop_title_en = ''
          this.formData.workshop_title_bn = ''
          this.formData.date_from = ''
          this.formData.date_to = ''
          this.formData.scheduleEntryDataArr = []
          }
          // this.$refs.trainingForm.reset()
          // this.$refs.workshopForm.reset()
          // this.$refs.courseForm.reset()
      }
    }
  },
  methods: {
    // ================Edu Info Start=================
    async scheduleEntryAddItem () {
      if (this.scheduleEntryData.event_name_en && this.scheduleEntryData.event_name_bn && this.scheduleEntryData.start_time) {
        const obj = Object.assign({}, this.scheduleEntryData)
        this.formData.scheduleEntryDataArr.push(obj)
      } else {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please Add Required Field'
        })
      }
      this.arrScheduleEntryData = this.formData.scheduleEntryDataArr
      this.$refs.workshopForm.reset()
      this.blankForm()
    },
    cancel () {
      this.$router.push({ path: '/tea-garden-service/btri/prepare-schedule' })
    },
    scheduleEntryRemove (key) {
      this.formData.scheduleEntryDataArr.splice(key, 1)
    },
        // ================Edu Info End===================
    async saveData () {
      // const isValid = await this.$refs.mainForm.validate()
      // if (isValid && this.formData.scheduleEntryDataArr.length > 0 && this.overBudget) {
      if (!this.$route.params.id && !this.arrScheduleEntryData.length && this.formData.event_type === 2) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please add at least one event'
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.params.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${prepareScheduleUpdate}/${this.$route.params.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, prepareScheduleStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.params.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        // this.$bvModal.hide('modal-form')
        this.$router.push({ path: '/tea-garden-service/btri/prepare-schedule' })
      } else {
        this.$refs.mainForm.setErrors(result.errors)
      }
    // }
    },
    getEditEntry () {
      const itemId = this.$route.params.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    },
    blankForm () {
      this.scheduleEntryData.event_name_en = ''
      this.scheduleEntryData.event_name_bn = ''
      this.scheduleEntryData.start_time = ''
    },
    disableField () {
      if (this.formData.event_type === 2) {
        if (this.arrScheduleEntryData && this.arrScheduleEntryData.length === 0) {
          return 1
        } else {
          return 0
        }
      } else {
        return 1
      }
    }
  }
}
</script>
